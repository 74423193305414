import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  FilterRow,
  HeaderFilter,
  Lookup,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { useCallback, useRef } from 'react';
import { useGetAllLanguagesQuery } from '../../api/medapp/language.generated';
import { EditOrganizationDialog } from './EditOrganizationDialog';
import { useDispatch } from 'react-redux';
import { setOrganizationToEdit, toggleCreateOrganizationDialog } from '../../redux/modules/organization';
import infoDialog from '../dialogs/InfoDialog';
import DataSource from 'devextreme/data/data_source';
import { OrganizationMultiLang } from '../../api';
import { useGetAllOrganizationsMultiLangQuery } from '../../api/medapp/organization.generated';
import { CreateOrganizationDialog } from './AddOrganizationDialog';

export const OrganizationGrid = () => {
  const organizationGridRef = useRef<DataGrid<OrganizationMultiLang, string>>(null);

  const dispatch = useDispatch();

  const { currentData: allOrganizations, isFetching: isFetchingOrganizations } = useGetAllOrganizationsMultiLangQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { currentData: allLanguages, isFetching: isFetchingLanguages } = useGetAllLanguagesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const onCreate = useCallback(() => {
    dispatch(toggleCreateOrganizationDialog());
  }, [dispatch]);

  const onEdit = useCallback(
    (selectedItems: OrganizationMultiLang[] | undefined): void => {
      if (selectedItems === undefined || selectedItems.length === 0) {
        infoDialog({ message: 'No entry is selected' });
        return;
      }
      if (selectedItems.length > 1) {
        infoDialog({ message: 'Editing multiple organizations is currently not supported' });
        return;
      }
      if (selectedItems.length === 1) {
        dispatch(setOrganizationToEdit(selectedItems[0]));
      }
    },
    [dispatch],
  );

  return (
    <>
      <CreateOrganizationDialog />
      <EditOrganizationDialog />
      <LoadPanel
        visible={isFetchingOrganizations || isFetchingLanguages}
        position={{ my: 'center', at: 'center', of: `#organizationDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        ref={organizationGridRef}
        id={'organizationDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        repaintChangesOnly={true}
        dataSource={new DataSource(allOrganizations ?? [])}
      >
        <LoadPanelProp enabled={false} />
        <Selection mode={'multiple'} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Toolbar>
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'plus',
              onClick: () => onCreate(),
            }}
            location={'after'}
          />
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'edit',
              onClick: () => onEdit(organizationGridRef.current?.instance.getSelectedRowsData()),
            }}
            location={'after'}
          />
        </Toolbar>
        <Column dataField={'code'} />
        <Column dataField={'phoneNumber'} />
        <Column dataField={'website'} />
        <Column dataField={'mainLanguageId'}>
          <Lookup dataSource={allLanguages} valueExpr={'id'} displayExpr={'code'} />
        </Column>
        <Column dataField={'supportedLanguageIds'} />
        <Column dataField={'logoUrl'} />
        <Column dataField={'covorPhotoUrl'} />
      </DataGrid>
    </>
  );
};
