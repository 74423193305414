import { enhancedApi } from './clinic.generated';

export const registerEnhancedClinicEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllClinicsMultilang: {
        providesTags: () => ['Clinic'],
      },
      getAllClinics: {
        providesTags: () => ['Clinic'],
      },
      updateClinic: {
        invalidatesTags: ['Clinic'],
      },
    },
  });
};
