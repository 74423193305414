import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalendarForUserByPeriod: build.query<GetCalendarForUserByPeriodApiResponse, GetCalendarForUserByPeriodApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/calendar/${queryArg.userId}`,
        headers: { timeZone: queryArg.timeZone },
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
    getCalendarForCurrentUserByPeriod: build.query<
      GetCalendarForCurrentUserByPeriodApiResponse,
      GetCalendarForCurrentUserByPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/calendar/${queryArg.startDate}/${queryArg.endDate}`,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getCalendarForSpecialityByPeriod: build.query<
      GetCalendarForSpecialityByPeriodApiResponse,
      GetCalendarForSpecialityByPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/calendar/speciality/${queryArg.specialityId}`,
        headers: { timeZone: queryArg.timeZone },
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetCalendarForUserByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForUserByPeriodApiArg = {
  userId: number;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
};
export type GetCalendarForCurrentUserByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForCurrentUserByPeriodApiArg = {
  startDate: string;
  endDate: string;
  timeZone?: string;
};
export type GetCalendarForSpecialityByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForSpecialityByPeriodApiArg = {
  specialityId: number;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
};
export type WorkingHoursDto = {
  id?: number;
  clinicId?: number;
  scheduleId?: number;
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  startTime?: string;
  endTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
};
export type ScheduleDto = {
  id?: number;
  userId: number;
  clinicId: number;
  countryCode?: string;
  startTime: string;
  endTime: string;
  usualInitialAppointmentDuration?: number;
  usualSecondaryAppointmentDuration?: number;
  intervalBetweenAppointments?: number;
  workingHours?: WorkingHoursDto[];
};
export type AppointmentDto = {
  id?: number;
  userId: number;
  patientId?: number;
  patientName?: string;
  phoneNumber?: string;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
  appointmentType: 'INITIAL' | 'SECONDARY' | 'OPEN' | 'INSURANCE' | 'OTHER' | 'ABSENCE';
  startAt: string;
  endAt?: string;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type UserMultilangDto = {
  id?: number;
  firstName?: LocalizedStringDto[];
  lastName?: LocalizedStringDto[];
  clinicIds?: number[];
  specialityIds?: number[];
  username: string;
  phoneNumber?: string;
  emailAddress?: string;
  password?: string;
  passwordTemporary?: boolean;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
  internal?: boolean;
};
export type CalendarDto = {
  schedules?: ScheduleDto[];
  appointments?: AppointmentDto[];
  specialists?: UserMultilangDto[];
};
export const {
  useGetCalendarForUserByPeriodQuery,
  useGetCalendarForCurrentUserByPeriodQuery,
  useGetCalendarForSpecialityByPeriodQuery,
} = injectedRtkApi;
