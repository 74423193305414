import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateSpeciality: build.mutation<UpdateSpecialityApiResponse, UpdateSpecialityApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/speciality/edit/${queryArg.specialityId}`,
        method: 'PUT',
        body: queryArg.specialityMultiLangDto,
      }),
    }),
    createSpeciality: build.mutation<CreateSpecialityApiResponse, CreateSpecialityApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/speciality/create`,
        method: 'POST',
        body: queryArg.specialityMultiLangDto,
      }),
    }),
    getSpecialityDetails: build.query<GetSpecialityDetailsApiResponse, GetSpecialityDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/speciality/details/${queryArg.specialityId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllSpeciality: build.query<GetAllSpecialityApiResponse, GetAllSpecialityApiArg>({
      query: (queryArg) => ({ url: `/api/v1/speciality/all`, params: { language: queryArg.language } }),
    }),
    getAllSpecialityMultiLang: build.query<GetAllSpecialityMultiLangApiResponse, GetAllSpecialityMultiLangApiArg>({
      query: () => ({ url: `/api/v1/speciality/all-multilingual` }),
    }),
    getAllSpecialityInUse: build.query<GetAllSpecialityInUseApiResponse, GetAllSpecialityInUseApiArg>({
      query: (queryArg) => ({ url: `/api/v1/speciality/all-in-use`, params: { language: queryArg.language } }),
    }),
    getCalendarForSpecialityByPeriod: build.query<
      GetCalendarForSpecialityByPeriodApiResponse,
      GetCalendarForSpecialityByPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/calendar/speciality/${queryArg.specialityId}`,
        headers: { timeZone: queryArg.timeZone },
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
    deleteSpeciality: build.mutation<DeleteSpecialityApiResponse, DeleteSpecialityApiArg>({
      query: (queryArg) => ({ url: `/api/v1/speciality/delete/${queryArg.specialityId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateSpecialityApiResponse = /** status 200 OK */ object;
export type UpdateSpecialityApiArg = {
  specialityId: number;
  specialityMultiLangDto: SpecialityMultiLangDto;
};
export type CreateSpecialityApiResponse = /** status 200 OK */ object;
export type CreateSpecialityApiArg = {
  specialityMultiLangDto: SpecialityMultiLangDto;
};
export type GetSpecialityDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetSpecialityDetailsApiArg = {
  specialityId: number;
  language?: string;
};
export type GetAllSpecialityApiResponse = /** status 200 OK */ SpecialityMultiLangDto[];
export type GetAllSpecialityApiArg = {
  language: string;
};
export type GetAllSpecialityMultiLangApiResponse = /** status 200 OK */ SpecialityMultiLangDto[];
export type GetAllSpecialityMultiLangApiArg = void;
export type GetAllSpecialityInUseApiResponse = /** status 200 OK */ SpecialityMultiLangDto[];
export type GetAllSpecialityInUseApiArg = {
  language: string;
};
export type GetCalendarForSpecialityByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForSpecialityByPeriodApiArg = {
  specialityId: number;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
};
export type DeleteSpecialityApiResponse = /** status 200 OK */ object;
export type DeleteSpecialityApiArg = {
  specialityId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type SpecialityMultiLangDto = {
  name: LocalizedStringDto[];
  userCount?: number;
  id?: number;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export type WorkingHoursDto = {
  id?: number;
  clinicId?: number;
  scheduleId?: number;
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  startTime?: string;
  endTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
};
export type ScheduleDto = {
  id?: number;
  userId: number;
  clinicId: number;
  countryCode?: string;
  startTime: string;
  endTime: string;
  usualInitialAppointmentDuration?: number;
  usualSecondaryAppointmentDuration?: number;
  intervalBetweenAppointments?: number;
  workingHours?: WorkingHoursDto[];
};
export type AppointmentDto = {
  id?: number;
  userId: number;
  patientId?: number;
  patientName?: string;
  phoneNumber?: string;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
  appointmentType: 'INITIAL' | 'SECONDARY' | 'OPEN' | 'INSURANCE' | 'OTHER' | 'ABSENCE';
  startAt: string;
  endAt?: string;
};
export type UserMultilangDto = {
  id?: number;
  firstName?: LocalizedStringDto[];
  lastName?: LocalizedStringDto[];
  clinicIds?: number[];
  specialityIds?: number[];
  username: string;
  phoneNumber?: string;
  emailAddress?: string;
  password?: string;
  passwordTemporary?: boolean;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
  internal?: boolean;
};
export type CalendarDto = {
  schedules?: ScheduleDto[];
  appointments?: AppointmentDto[];
  specialists?: UserMultilangDto[];
};
export const {
  useUpdateSpecialityMutation,
  useCreateSpecialityMutation,
  useGetSpecialityDetailsQuery,
  useGetAllSpecialityQuery,
  useGetAllSpecialityMultiLangQuery,
  useGetAllSpecialityInUseQuery,
  useGetCalendarForSpecialityByPeriodQuery,
  useDeleteSpecialityMutation,
} = injectedRtkApi;
