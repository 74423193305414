import React from 'react';
import { OrganizationGrid } from '../../../components/OrganizationGrid/OrganizationGrid';

export default function Organization() {
  return (
    <div className={'content-block'}>
      <OrganizationGrid />
    </div>
  );
}
