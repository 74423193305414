import React from 'react';
import { AddressGrid } from '../../../components/AddressGrid/AddressGrid';

export default function AddressMultiLang() {
  return (
    <div className={'content-block'}>
      <AddressGrid />
    </div>
  );
}
