import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { OrganizationMultiLang } from '../../api';

export type OrganizationState = {
  organizationToEdit?: OrganizationMultiLang;
  showCreateOrganizationDialog: boolean;
};

const initialState: OrganizationState = {
  organizationToEdit: undefined,
  showCreateOrganizationDialog: false,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationToEdit(state, action: PayloadAction<OrganizationMultiLang | undefined>) {
      state.organizationToEdit = action.payload;
    },
    toggleCreateOrganizationDialog(state) {
      state.showCreateOrganizationDialog = !state.showCreateOrganizationDialog;
    },
  },
});

export const { setOrganizationToEdit, toggleCreateOrganizationDialog } = organizationSlice.actions;
export default organizationSlice.reducer;

export const organizationToEditSelector: (state: RootState) => OrganizationMultiLang | undefined = (state: RootState) =>
  state.organization.organizationToEdit;

export const createOrganizationDialogVisibleSelector: (state: RootState) => boolean = (state: RootState) =>
  state.organization.showCreateOrganizationDialog;
