import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  useCreateLanguageMutation,
  useGetAllLanguagesQuery,
  useUpdateLanguageMutation,
} from '../../api/medapp/language.generated';
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  StringLengthRule,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { cloneDeep } from 'lodash';
import { Language } from '../../api/medapp/generated';

export type LanguageUpdateDto = Omit<Language, 'id'> & {
  id: number;
};

export const LanguageGrid = () => {
  const { currentData: languages, isFetching: isFetchingLanguages } = useGetAllLanguagesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createLanguage, { isLoading: isCreatingLanguage }] = useCreateLanguageMutation();
  const [updateLanguage, { isLoading: isUpdatingLanguage }] = useUpdateLanguageMutation();

  const create = (language: Language) => {
    createLanguage({
      code: language.code,
    });
  };

  const update = (updatedLanguage: LanguageUpdateDto) => {
    updateLanguage({ languageId: updatedLanguage.id, languageDto: updatedLanguage });
  };

  return (
    <>
      <LoadPanel
        visible={isFetchingLanguages || isCreatingLanguage || isUpdatingLanguage}
        position={{ my: 'center', at: 'center', of: `#languageDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        id="languageDataGrid"
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(languages)}
        onRowInserting={({ data }) => {
          create(data);
        }}
        onRowUpdating={({ oldData, newData }) => {
          update({ id: (oldData as LanguageUpdateDto).id, code: newData.code ?? oldData.code });
        }}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column dataField={'id'} caption={'ID'} editorOptions={{ disabled: true }} alignment={'left'} />
        <Column dataField={'code'} caption={'Language'} allowEditing={true} alignment={'left'}>
          <StringLengthRule min={2} max={2} message={'Language length must be exact 2 letters'} />
        </Column>
      </DataGrid>
    </>
  );
};
