import { enhancedApi } from './workingHours.generated';

export const registerEnhancedWorkingHoursEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllWorkingHours: {
        providesTags: () => ['WorkingHours'],
      },
      editWorkingHours: {
        invalidatesTags: ['WorkingHours'],
      },
      createWorkingHours: {
        invalidatesTags: ['WorkingHours'],
      },
      deleteWorkingHours: {
        invalidatesTags: ['WorkingHours'],
      },
      getWorkingHoursBySchedule: {
        providesTags: () => ['WorkingHours'],
      },
    },
  });
};
