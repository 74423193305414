import { enhancedApi } from './language.generated';

export const registerEnhancedLanguageEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllLanguages: {
        providesTags: () => ['Language'],
      },
      createLanguage: {
        invalidatesTags: ['Language'],
      },
      updateLanguage: {
        invalidatesTags: ['Language'],
      },
    },
  });
};
