import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './header.scss';
import SelectBox from 'devextreme-react/select-box';
import { LANGUAGES, LanguageType } from '../../constants';
import { switchLanguage, languageSelector } from '../../redux/modules/user';
import UserPanel from '../UserPanel/UserPanel';
import { dxButtonOptions } from 'devextreme/ui/button';
import { useAppSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';

type HeaderProps = {
  title: string;
  menuToggleEnabled: boolean;
  toggleMenu: dxButtonOptions['onClick'];
};

type ChangeLanguageArg = {
  selectedItem?: LanguageType;
};
/**
 * Header of the UI which contains the toolbar.
 */
const Header = (props: HeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const languageId = useAppSelector(languageSelector);
  const changeLanguage = (e: ChangeLanguageArg) => {
    if (e.selectedItem !== undefined) {
      sessionStorage.setItem('language', e.selectedItem.id);
      dispatch(switchLanguage(e.selectedItem.id));
    } else console.log(e.selectedItem);
  };

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={props.menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={props.toggleMenu} />
        </Item>
        <Item location="after">
          <SelectBox
            dataSource={LANGUAGES}
            width={80}
            defaultValue={languageId}
            selectedItem={LANGUAGES.find((elem) => elem.id === languageId)}
            displayExpr="text"
            valueExpr="id"
            onSelectionChanged={changeLanguage}
            style={{ borderBottom: 'none' }}
          />
        </Item>
        <Item location={'after'}>
          <UserPanel />
        </Item>
      </Toolbar>
    </header>
  );
};

export default Header;
