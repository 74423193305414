/** @jsxRuntime classic */
/** @jsx h */
import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { InstantSearch } from 'instantsearch.js';

import {
  debouncedSetInstantSearchUiState,
  getInstantSearchUiState,
  getInstantSearchUrl,
  setInstantSearchUiState,
} from './instantsearch';
import { isModifierEvent } from './isModifierEvent';
import { indexName, searchClient } from './searchClient';
import i18n from '../../translation/config';
import { createNameInitials, generatePhotoBySize, getTranslatedValue } from '../helpers';
import { LocalizedStringDTO } from '../../api/medapp/generated';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

export type AlgoliaItem = {
  objectID: string;
  email: string[];
  username: string;
  clinicIdList: number[];
  specialties: LocalizedStringDTO[];
  firstName: LocalizedStringDTO[];
  lastName: LocalizedStringDTO[];
  phoneNumber: string;
  profilePicture: string;
};

const doctorDescription = (item: AlgoliaItem) => {
  const fn = getTranslatedValue(item.firstName, i18n.language);
  const ln = getTranslatedValue(item.lastName, i18n.language);
  const allSpecialities = item.specialties
    .filter((s) => s.language === i18n.language)
    .map((s) => s.value)
    .join(', ');

  return {
    name: `${fn} ${ln}`,
    specialties: allSpecialities,
  };
};

function onSelect({ setIsOpen, setQuery, event, query, algoliaItem }: any) {
  // You want to trigger the default browser behavior if the event is modified.
  if (isModifierEvent(event)) {
    return;
  }

  document.dispatchEvent(new CustomEvent('algoliaSearchStateUpdated', { detail: algoliaItem }));
  setQuery(query);
  setIsOpen(false);
  setInstantSearchUiState({
    query,
  });
}

function getItemUrl({ query }: any) {
  return getInstantSearchUrl({
    query,
  });
}

function getItemWrapper({ html, children, query, algoliaItem }: any) {
  const uiState = {
    query,
  };

  let profileImageDiv;
  if (algoliaItem.profilePicture) {
    profileImageDiv = html`
      <img
        src="${generatePhotoBySize('small', algoliaItem.profilePicture)}"
        alt="Profile Picture"
        style="width: 100%; height: 100%; object-fit: cover;"
      />
    `;
  } else {
    const nameInitials = createNameInitials(
      { firstName: algoliaItem.firstName, lastName: algoliaItem.lastName },
      i18n.language,
    );

    profileImageDiv = html`
      <span
        class="profile-initials"
        style="width: 100%; height: 100%; border-radius: 50%; 
             display: flex; align-items: center; justify-content: center; 
             background-color: #ccc; color: #fff; font-weight: bold;"
      >
        ${nameInitials}
      </span>
    `;
  }
  const doctorData = doctorDescription(algoliaItem as AlgoliaItem);

  return html`
    <style>
      .aa-ItemLink {
        display: flex;
        font-size: 4vw;
      }

      @media (max-width: 479px) {
        .doctor-description {
          font-size: 2.5vw !important;
        }
      }

      @media (min-width: 480px) and (max-width: 766px) {
        .doctor-description {
          font-size: 4vw !important;
        }
      }

      @media (min-width: 767px) and (max-width: 1022px) {
        .doctor-description {
          font-size: 2.5vw !important;
        }
      }

      @media (min-width: 1023px) and (max-width: 1199px) {
        .doctor-description {
          font-size: 2.5vw !important;
        }
      }

      @media (min-width: 1200px) and (max-width: 1799px) {
        .doctor-description {
          font-size: 1.5vw !important;
        }
      }

      @media (min-width: 1800px) {
        .doctor-description {
          font-size: 1.5vw !important;
        }
      }
    </style>

    <a
      class="aa-ItemLink"
      href=${getInstantSearchUrl(uiState)}
      onClick=${(event: any) => {
        if (!isModifierEvent(event)) {
          event.preventDefault();
        }
      }}
    >
      <div style="width: 100%; display: flex; align-items: center;">
        <div style="width: 10%; aspect-ratio: 1; border-radius: 50%; overflow: hidden;">${profileImageDiv}</div>
        <div style="width: 100%; display: flex;">
          <div
            class="doctor-description"
            style=" padding-left: 1vw; text-overflow: ellipsis; font-weight: 500; white-space: nowrap;"
          >
            ${doctorData.name}
          </div>

          <div
            class="doctor-description"
            style="flex: 1; white-space: nowrap; text-overflow: ellipsis; color: gray; padding-left: 1vw; flex-shrink: 1;"
          >
            ${doctorData.specialties}
          </div>
        </div>
      </div>
    </a>
  `;
}

const querySuggestionsPlugin = (clinicIdList: number[]) =>
  createQuerySuggestionsPlugin({
    searchClient,
    indexName: indexName,
    transformSource({ source }: any) {
      return {
        ...source,
        sourceId: 'querySuggestionsPlugin',
        getItemUrl({ item }: any) {
          return getItemUrl({
            query: item.query,
          });
        },
        onSelect({ setIsOpen, setQuery, event, item }: any) {
          const doctorData = doctorDescription(item as AlgoliaItem);
          onSelect({
            setQuery,
            setIsOpen,
            event,
            query: doctorData.name + ' ' + doctorData.specialties,
            algoliaItem: item,
          });
        },
        getItems(params) {
          if (!params.state.query) {
            return [];
          }

          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: indexName,
                query: params.state.query,
                params: {
                  // filters: `clinicIdList:${clinicIdList}`,
                  // filters: clinicIdList.map(id => `clinicIdList:${id}`).join(' OR '),
                  hitsPerPage: 6, // This number controls how many results we show in the dropdown
                },
              },
            ],
          });
        },
        templates: {
          ...source.templates,
          item(params) {
            const { children } = (source.templates.item(params) as any).props;
            const { item, html }: any = params;

            return getItemWrapper({
              query: item.query,
              algoliaItem: item,
              html,
              children,
            });
          },
        },
      };
    },
  });

const searchPageState = getInstantSearchUiState();

export function startAutocomplete(searchInstance: InstantSearch, clinicIdList: number[]) {
  let skipInstantSearchStateUpdate = false;

  const placeholder = i18n.t('algoliaSearchPlaceholder');
  const { setQuery } = autocomplete({
    container: '#autocomplete',
    placeholder: placeholder,
    insights: true,
    openOnFocus: true,
    plugins: [querySuggestionsPlugin(clinicIdList)],
    detachedMediaQuery: 'none',
    initialState: {
      query: searchPageState.query || '',
    },
    navigator: {
      navigate() {
        // We don't navigate to a new page because we leverage the InstantSearch
        // UI state API.
      },
    },
    onSubmit({ state }) {
      setInstantSearchUiState({ query: state.query });
    },
    onReset() {
      //reset/clean scheduler view
      document.dispatchEvent(new CustomEvent('algoliaSearchStateUpdated', { detail: { objectID: 0 } }));
      setInstantSearchUiState({
        query: '',
      });
    },
    onStateChange({ prevState, state }) {
      if (!skipInstantSearchStateUpdate && prevState.query !== state.query) {
        debouncedSetInstantSearchUiState({ query: state.query });
      }
      skipInstantSearchStateUpdate = false;
    },
  });

  window.addEventListener('popstate', () => {
    skipInstantSearchStateUpdate = true;
    setQuery((searchInstance.helper && searchInstance.helper.state.query) || '');
  });
}
