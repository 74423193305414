import { DataGrid, LoadPanel } from 'devextreme-react';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAllAddressesMultilangQuery } from '../../api/medapp/address.generated';
import { useGetAllCityMultiLangQuery } from '../../api/medapp/city.generated';
import {
  FilterRow,
  HeaderFilter,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
  Column,
  Lookup,
} from 'devextreme-react/data-grid';
import { AddressMultiLang, CityMultiLang } from '../../api';
import DataSource from 'devextreme/data/data_source';
import infoDialog from '../dialogs/InfoDialog';
import { EditAddressDialog } from './EditAddressDialog';
import { setAddressToEdit } from '../../redux/modules/address';
import { useTranslation } from 'react-i18next';
import { toggleCreateAddressDialog } from '../../redux/modules/address';
import { CreateAddressDialog } from './CreateAddressDialog';
import { getTranslatedValue } from '../helpers';

export const AddressGrid = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const language = useMemo(() => i18n.language, [i18n.language]);

  const addressGridRef = useRef<DataGrid<AddressMultiLang, string>>(null);

  const { currentData: allCities, isFetching: isFetchingCities } = useGetAllCityMultiLangQuery();

  const { currentData: allAddress, isFetching: isFetchingAddress } = useGetAllAddressesMultilangQuery();

  const onCreate = useCallback(() => {
    dispatch(toggleCreateAddressDialog());
  }, [dispatch]);

  const onEdit = useCallback(() => {
    const selectedItems = addressGridRef.current?.instance.getSelectedRowsData();
    if (selectedItems === undefined || selectedItems.length === 0) {
      infoDialog({ message: 'No entry is selected' });
      return;
    }
    if (selectedItems.length > 1) {
      infoDialog({ message: 'Editing multiple addresses is currently not supported' });
      return;
    }
    if (selectedItems.length === 1) {
      dispatch(setAddressToEdit(selectedItems[0]));
    }
  }, [dispatch]);

  const calculateCityDisplayExpr = useCallback(
    (data: CityMultiLang) => {
      return data.name.find((name) => name.language === language)?.value;
    },
    [language],
  );

  const calculateStreetCellValue = useCallback(
    (data: AddressMultiLang) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  return (
    <>
      <EditAddressDialog />
      <CreateAddressDialog />
      <LoadPanel
        visible={isFetchingAddress || isFetchingCities}
        position={{ my: 'center', at: 'center', of: `#adressDataGrid` }}
      />
      <DataGrid
        ref={addressGridRef}
        id={'addressDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        repaintChangesOnly={true}
        dataSource={new DataSource(allAddress ?? [])}
      >
        <LoadPanelProp enabled={false} />
        <Selection mode={'multiple'} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Toolbar>
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'plus',
              onClick: () => onCreate(),
            }}
            location={'after'}
          />
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'edit',
              onClick: onEdit,
            }}
            location={'after'}
          />
        </Toolbar>
        <Column dataField={'id'} caption={'ID'} />
        <Column dataField={'cityId'} caption={'City'}>
          <Lookup dataSource={allCities} valueExpr={'id'} displayExpr={calculateCityDisplayExpr} />
        </Column>
        <Column dataField={'street'} calculateCellValue={calculateStreetCellValue} />
        <Column dataField={'streetNumber'} caption={'Street Number'} />
      </DataGrid>
    </>
  );
};
