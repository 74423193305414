import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  LocalizedStringDto,
  useCreateRegionMutation,
  useGetAllRegionsMultiLangQuery,
  useUpdateRegionMutation,
} from '../../api/medapp/region.generated';
import {
  Column,
  FilterRow,
  HeaderFilter,
  Lookup,
  MasterDetail,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { Editing } from 'devextreme-react/scheduler';
import { updateObjectAttribute, updateObjectLanguage } from '../helpers';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { useGetAllCountryQuery } from '../../api/medapp/country.generated';
import { CountryMultiLang, RegionMultiLang } from '../../api';

export const RegionGrid = () => {
  const { i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const { currentData: allRegions, isFetching: isFetchingRegions } = useGetAllRegionsMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { currentData: allCountries, isFetching: isFetchingCountries } = useGetAllCountryQuery(
    { language: language },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [createRegion, { isLoading: isCreatingRegion }] = useCreateRegionMutation();
  const [updateRegion, { isLoading: isUpdatingRegion }] = useUpdateRegionMutation();

  const create = (data: RegionMultiLang) => {
    createRegion({
      regionMultiLangDto: data,
    });
  };

  const displayCountry = useCallback(
    (data: CountryMultiLang) => {
      const countryName = data.name?.find((name: any) => name.language === `${language}`)?.value;
      return countryName;
    },
    [language],
  );

  const updateRegionAttribute = (oldData: RegionMultiLang, newData: unknown) => {
    if (oldData.regionId) {
      updateRegion({
        regionId: oldData!.regionId,
        regionMultiLangDto: updateObjectAttribute(oldData, newData),
      });
    }
  };

  const updateRegionLanguage = useCallback(
    (region: RegionMultiLang, oldData: LocalizedStringDto, newData: { value: string }) => {
      if (region.regionId) {
        updateRegion({
          regionId: region.regionId,
          regionMultiLangDto: updateObjectLanguage(cloneDeep(region), oldData.language, newData.value),
        });
      }
    },
    [updateRegion],
  );

  const detailView = useCallback(
    (region: RegionMultiLang, names: LocalizedStringDto[]) => (
      <LanguageDetailGrid
        names={names}
        onRowUpdating={(oldData, newData) => {
          updateRegionLanguage(region, oldData, newData);
        }}
      />
    ),
    [updateRegionLanguage],
  );

  return (
    <>
      <LoadPanel
        visible={isFetchingRegions || isCreatingRegion || isUpdatingRegion || isFetchingCountries}
        position={{ my: 'center', at: 'center', of: `#regionDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        id={'regionDataGrid'}
        // height={'100%'}
        // width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(allRegions)}
        onRowInserting={({ data }) => create(data)}
        onRowUpdating={({ oldData, newData }) => {
          updateRegionAttribute(oldData, newData);
        }}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Column dataField={'regionId'} allowEditing={false} />
        <Column dataField={'code'} caption={'Region'} />
        <Column dataField={'countryId'} caption={'Country'}>
          <Lookup dataSource={allCountries} valueExpr={'id'} displayExpr={displayCountry} />
        </Column>
        <MasterDetail enabled={true} component={({ data }) => detailView(data.data, data.data.name)} />
      </DataGrid>
    </>
  );
};
