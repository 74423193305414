import Form, { SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { RefObject, useState } from 'react';
import { OrganizationMultiLangDTO } from '../../api/medapp/generated';
import { LocalizedStringDto, useGetAllClinicsQuery } from '../../api/medapp/clinic.generated';
import { updateObjectLanguage } from '../helpers';
import { LoadPanel, ScrollView } from 'devextreme-react';
import { useGetAllLanguagesQuery } from '../../api/medapp/language.generated';

const defaultEditorOptions = { stylingMode: 'outlined' };

export type OrganizationFormProps = {
  reference: RefObject<Form>;
  organization: OrganizationMultiLangDTO;
  showTranslationsTab?: boolean;
};

export const OrganizationForm = (props: OrganizationFormProps) => {
  //default values
  const { showTranslationsTab = true } = props;

  const { currentData: allClinics, isFetching: isFetchingClinics } = useGetAllClinicsQuery(
    { language: 'en' },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { currentData: languages, isFetching: isFetchingLanguages } = useGetAllLanguagesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const updateOrganizationTranslations = (oldData: LocalizedStringDto, newData: { value: string }) => {
    updateObjectLanguage(props.organization, oldData.language, newData.value);
  };

  const [mainLanguageChanged, setMainLanguageChanged] = useState(false);
  const supportedLanguageIds = languages?.filter((language) => language.id !== props.organization.mainLanguageId);

  return (
    <>
      <ScrollView>
        <LoadPanel
          visible={isFetchingClinics || isFetchingLanguages}
          position={{ my: 'center', at: 'center', of: `#EditOrganizationForm` }}
          shading={true}
          shadingColor={'rgba(0,0,0,.32)'}
          height="100%"
        />
        <Form id="EditOrganizationForm" ref={props.reference} formData={props.organization}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={'INFO'} colCount={2}>
              {/* TODO: FIX NAME to correspond latest structure */}
              <SimpleItem dataField={'name'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'code'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'phoneNumber'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'website'} editorOptions={defaultEditorOptions} />
              <SimpleItem
                dataField={'mainLanguageId'}
                editorOptions={{
                  ...defaultEditorOptions,
                  items: languages,
                  displayExpr: 'code',
                  valueExpr: 'id',
                  searchEnabled: true,
                  onValueChanged: () => {
                    setMainLanguageChanged(!mainLanguageChanged);
                    props.reference.current?.instance.updateData('supportedLanguageIds', []);
                  },
                }}
                editorType={'dxSelectBox'}
              />
              {!showTranslationsTab ? (
                <SimpleItem
                  dataField={'supportedLanguageIds'}
                  editorType="dxTagBox"
                  editorOptions={{
                    ...defaultEditorOptions,
                    items: supportedLanguageIds,
                    displayExpr: 'code',
                    valueExpr: 'id',
                    searchEnabled: true,
                  }}
                />
              ) : null}
              <SimpleItem dataField={'logoUrl'} editorOptions={defaultEditorOptions} />
              <SimpleItem dataField={'covorPhotoUrl'} editorOptions={defaultEditorOptions} />
              <SimpleItem
                dataField={'clinicsIds'}
                editorType="dxTagBox"
                editorOptions={{
                  ...defaultEditorOptions,
                  items: allClinics,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              />
            </Tab>
            {showTranslationsTab && (
              <Tab title={'TRANSLATIONS'}>
                <SimpleItem
                  render={() => (
                    <LanguageDetailGrid
                      names={props.organization?.name ?? []}
                      onRowUpdating={(oldData, newData) => {
                        updateOrganizationTranslations(oldData, newData);
                      }}
                    />
                  )}
                />
              </Tab>
            )}
          </TabbedItem>
        </Form>
      </ScrollView>
    </>
  );
};
