import { Appointment } from '..';
import { mapAppointmentFromApi } from '../dataMapper';
import { enhancedApi } from './appointment.generated';

export const registerEnhancedAppointmentEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllAppointments: {
        transformResponse: (responseData: Appointment[]) =>
          responseData.map((timeSlot) => mapAppointmentFromApi(timeSlot)),
        providesTags: () => ['Appointment'],
      },
      deleteAppointment: {
        invalidatesTags: ['Appointment', 'SchedulerComponentData'],
      },
      createAppointment: {
        invalidatesTags: ['Appointment', 'SchedulerComponentData'],
      },
      editAppointment: {
        invalidatesTags: ['Appointment', 'SchedulerComponentData'],
      },
      updateAppointmentStatus: {
        invalidatesTags: ['Appointment', 'SchedulerComponentData'],
      },
    },
  });
};
