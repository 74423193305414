import { Popup } from 'devextreme-react';
import { useCallback, useRef } from 'react';
import { useAppSelector } from '../../redux/store';
import { organizationToEditSelector, setOrganizationToEdit } from '../../redux/modules/organization';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useUpdateOrganizationMutation } from '../../api/medapp/organization.generated';
import { OrganizationForm } from './OrganizationForm';

export const EditOrganizationDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();

  const organizationToEdit = cloneDeep(useAppSelector(organizationToEditSelector));

  const cancelEditing = useCallback(() => {
    dispatch(setOrganizationToEdit(undefined));
  }, [dispatch]);

  const [updateOrganization] = useUpdateOrganizationMutation();

  const finish = useCallback(() => {
    if (organizationToEdit?.id && organizationToEdit) {
      updateOrganization({
        organizationId: organizationToEdit.id,
        organizationMultiLangDto: organizationToEdit,
      });
      dispatch(setOrganizationToEdit(undefined));
    }
  }, [dispatch, organizationToEdit, updateOrganization]);

  if (!organizationToEdit) return <div></div>;

  return (
    <div id={'EditOrganizationDialogContainer'}>
      <Popup visible={organizationToEdit !== undefined} resizeEnabled={true} showCloseButton={false}>
        <OrganizationForm reference={formRef} organization={organizationToEdit} />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Confirm',
            elementAttr: { 'aria-label': 'confirm' },
            onClick: () => {
              const validationResult = formRef.current?.instance.validate();
              if (validationResult?.isValid) {
                finish();
              }
            },
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: 'Cancel',
            elementAttr: { 'aria-label': 'cancel' },
            onClick: () => cancelEditing(),
          }}
        />
      </Popup>
    </div>
  );
};
