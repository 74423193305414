import React, { useCallback, useMemo, useRef, useState } from 'react';
import Scheduler, { Resource, Scrolling, View } from 'devextreme-react/scheduler';
import { LoadPanel, ScrollView } from 'devextreme-react';
import { WorkingHoursDto, useGetCalendarForSpecialityByPeriodQuery } from '../../api/medapp/calendar.generated';
import { AppointmentComponent } from '../SchedulerComponent/SchedulerRenderers';
import { AppointmentDTO } from '../../api/medapp/generated';
import { cloneDeep, flattenDeep } from 'lodash';
import { calculateAppointmentColor, generateItems, generatePhotoBySize, getTranslatedValue } from '../helpers';
import Utils from '../SchedulerComponent/utils';
import { DateTime, Interval } from 'luxon';
import Toolbar from 'devextreme/ui/toolbar';
import { useGetAllSpecialityInUseQuery } from '../../api/medapp/speciality.generated';
import { useTranslation } from 'react-i18next';
import dxSelectBox from 'devextreme/ui/select_box';
import dxForm from 'devextreme/ui/form';
import { Appointment } from 'devextreme/ui/scheduler';
import { useCreateAppointmentMutation, useEditAppointmentMutation } from '../../api/medapp/appointment.generated';
import Holidays from 'date-holidays';
import './schedulerBySpeciality.scss';

type CreateAppointmentDTO = Pick<
  AppointmentDTO,
  'appointmentType' | 'status' | 'userId' | 'patientName' | 'phoneNumber' | 'startAt' | 'endAt'
>;

export const SchedulerBySpeciality = (): JSX.Element => {
  const schedulerRef = useRef<Scheduler>(null);

  const { t, i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const appointmentTypesTranslations = t('appointmentTypes', { returnObjects: true });
  const appointmentStatusesTranslations = t('appointmentStatuses', { returnObjects: true });

  const [currentViewDate, setCurrentViewDate] = useState(new Date().toISOString().slice(0, 10));

  const [specialityId, setSpecialityId] = useState(0);

  const [createAppointment] = useCreateAppointmentMutation();
  const [updateAppointment] = useEditAppointmentMutation();

  const { currentData: calendarData, isFetching: isFetchingCalendar } = useGetCalendarForSpecialityByPeriodQuery(
    {
      specialityId: specialityId,
      startDate: currentViewDate,
      endDate: currentViewDate,
    },
    {
      skip: specialityId === 0,
      refetchOnMountOrArgChange: true,
    },
  );

  const { currentData: allSpecialities } = useGetAllSpecialityInUseQuery({
    language: language,
  });

  const setDate = useCallback(() => {
    if (schedulerRef.current && specialityId !== 0) {
      const date = schedulerRef.current.instance.getStartViewDate()?.toISOString().split('T')[0];
      if (date && date !== currentViewDate) {
        setCurrentViewDate(date);
      }
    }
  }, [specialityId, currentViewDate]);

  const initHD = useMemo(() => {
    if (calendarData?.schedules?.[0] !== undefined) {
      const hd = new Holidays();
      hd.init(calendarData?.schedules?.[0].countryCode, { types: ['public'] });
      return hd.getHolidays();
    }
  }, [calendarData]);

  const scheduleIntervals = useMemo(() => {
    if (calendarData?.schedules !== undefined) {
      const intervals: Interval[] = [];
      calendarData?.schedules?.forEach((schedule: any) =>
        intervals.push(Interval.fromDateTimes(new Date(schedule.startTime), new Date(schedule.endTime))),
      );
      return intervals;
    }
  }, [calendarData?.schedules]);

  const workingHoursForCurrentView = useMemo(() => {
    if (calendarData?.schedules !== undefined) {
      const workingHours: WorkingHoursDto[][] = [];
      const viewStartDate = schedulerRef.current?.instance.getStartViewDate();
      const viewEndDate = schedulerRef.current?.instance.getEndViewDate();

      if (calendarData.schedules.length > 0) {
        calendarData.schedules.forEach((schedule: any) => {
          if (viewStartDate && viewEndDate && schedule.workingHours) {
            workingHours.push(schedule.workingHours);
          }
        });
        return flattenDeep(workingHours);
      }
    }
  }, [calendarData?.schedules]);

  const onAppointmentRender = useCallback((e: any) => {
    const { appointmentData } = e;
    e.appointmentElement.style.backgroundColor = calculateAppointmentColor(appointmentData) ?? '';
  }, []);

  const appointmentRenderer = useCallback((e: { targetedAppointmentData: AppointmentDTO }) => {
    const { targetedAppointmentData } = e;
    return AppointmentComponent(targetedAppointmentData);
  }, []);

   const calculateDaysOffForCountry = useMemo(() => {
     if (initHD !== undefined && calendarData?.schedules && calendarData.schedules.length > 0) {
       if (calendarData?.schedules?.[0] && calendarData?.schedules?.[0].countryCode === 'BG') {
         const modifiedDaysOffForBg = initHD
           .map((day: any) => {
             const dayDate = new Date(day.date);
             if (dayDate.getDay() === 0) {
               // 0 is sunday => if day is sunday -> make monday off
               const nextWorkingDay = new Date(dayDate);
               nextWorkingDay.setDate(nextWorkingDay.getDate() + 1);
               const dateResult = DateTime.fromJSDate(nextWorkingDay);
               return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
             }

             if (dayDate.getDay() === 6) {
               // 6 is saturday => if day is saturday -> make monday off
               const nextWorkingDay = new Date(dayDate);
               nextWorkingDay.setDate(nextWorkingDay.getDate() + 2);
               const dateResult = DateTime.fromJSDate(nextWorkingDay);
               return [{ date: dateResult.toFormat('yyyy-MM-dd HH:mm:ss'), name: day.name }];
             }
             return [day];
           })
           .flat();
         return modifiedDaysOffForBg;
       } else return initHD;
     } else return [];
   }, [initHD, calendarData?.schedules]);

  const isNationalHoliday = useCallback(
    (date: Date) => {
      const days = calculateDaysOffForCountry as { date: string; name: string }[];
      if (days.length > 0) {
        for (let i = 0; i < days.length; i++) {
          const holidayDate = new Date(days[i].date);
          if (holidayDate.toDateString() === date.toDateString()) {
            return true;
          }
        }
      }
      return false;
    },
    [calculateDaysOffForCountry],
  );

  const dataCellTemplate = useCallback(
    (
      itemData: {
        scheduleId: number;
        groups: any;
        startDate: string | number | Date;
      },
      itemIndex: number,
      itemElement: HTMLElement,
    ) => {
      let isTimeSlotEnabled = false;
      let isHoliday = false;
      const rowUserWorkingHours = calendarData?.schedules?.find(
        (schedule: any) => schedule.userId === itemData.groups.userId,
      )?.workingHours;

      scheduleIntervals?.forEach((interval: Interval) => {
        if (rowUserWorkingHours && rowUserWorkingHours.length > 0) {
          const startDate = new Date(itemData.startDate);
          const isEnabledTime = Utils.isEnabledTime(startDate, rowUserWorkingHours, [interval]);
          isHoliday = isNationalHoliday(startDate);
          if (isEnabledTime) {
            isTimeSlotEnabled = true;
          }
        }
      });

      if (!isTimeSlotEnabled) {
        itemElement.classList.add('disable-date');
      }
      if (isHoliday) {
        itemElement.classList.add('date-is-national-holiday');
      }
    },
    [calendarData?.schedules, isNationalHoliday, scheduleIntervals],
  );

  const resourceCellRender = useCallback(
    (resource: any) => {
      const user = calendarData?.specialists?.find((user) => user.id === resource.data.userId);
      const fnMultiLang = getTranslatedValue(user?.firstName ?? [], language);
      const lnMultiLang = getTranslatedValue(user?.lastName ?? [], language);
      const fullName = `${fnMultiLang} ${lnMultiLang}`;
      if (user?.profilePicture) {
        return (
          <div className="nameAndPhotoDiv">
            <img
              src={generatePhotoBySize('small', user?.profilePicture) ?? ''}
              alt={fullName}
              style={{ width: '80%', borderRadius: '10%', margin: '5px' }}
            />
            <span>{fullName}</span>
          </div>
        );
      } else {
        return (
          <div className="nameDisplayDiv">
            <span style={{ textAlign: 'center' }}>{fullName}</span>
          </div>
        );
      }
    },
    [calendarData?.specialists, language],
  );

  const calculateSpec = useCallback((e: any) => {
    if (e) {
      return e.name[0].value + ' - ' + e.userCount;
    }
    return '';
  }, []);

  const onContentReady = useCallback(
    (e: any) => {
      setDate();
      const toolbarInstance = Toolbar.getInstance(e.element.querySelector('.dx-toolbar'));
      const items = toolbarInstance.option('items') as unknown[];
      if (items.length === 2) {
        toolbarInstance.option('items', [
          items[0],
          {
            widget: 'dxButton',
            location: 'before',
            options: {
              text: t('buttons.todayButton') || '',
              onClick: () => {
                const currentDate = new Date();
                schedulerRef.current?.instance.option('currentDate', currentDate);
              },
            },
          },
          {
            widget: 'dxSelectBox',
            location: 'before',
            options: {
              visible: true,
              width: '200px',
              displayExpr: calculateSpec,
              valueExpr: 'id',
              value: specialityId,
              onValueChanged: (e: any) => {
                setSpecialityId(e.value);
              },
              items: allSpecialities,
              searchEnabled: true,
              dropDownOptions: { width: '200px' },
            },
          },
          items[1],
        ]);
      }
    },
    [allSpecialities, calculateSpec, specialityId, t, setDate],
  );

  const onAppointmentFormOpening = useCallback(
    (e: { form: dxForm; appointmentData?: Appointment; cancel?: boolean }) => {
      const { form } = e;
      const appointmentStatuses = generateItems(appointmentStatusesTranslations).filter(
        (status) => status.value === 'SCHEDULED' || status.value === 'COMPLETED' || status.value === 'CANCELLED',
      );
      const appointmentTypes = generateItems(appointmentTypesTranslations).filter(
        (type) =>
          type.value === AppointmentDTO.appointmentType.INITIAL ||
          type.value === AppointmentDTO.appointmentType.SECONDARY ||
          type.value === AppointmentDTO.appointmentType.ABSENCE ||
          type.value === AppointmentDTO.appointmentType.OTHER,
      );

      const customItems = [
        {
          dataField: 'patientName',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.patientName')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'phoneNumber',
          colSpan: 2,
          editorType: 'dxTextBox',
          label: { text: `${t('appointmentForm.phone')}` },
          isRequired: true,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
        },
        {
          dataField: 'appointmentType',
          editorType: 'dxSelectBox',
          colSpan: 2,
          label: { text: `${t('appointmentType')}` },
          editorOptions: {
            items: appointmentTypes,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentTypes[0].value);
                form.updateData({ appointmentType: appointmentTypes[0].value });
              }
            },
            onValueChanged: (e: { value: any }) => {
              const { value } = e;
              const isVisibleEndAt =
                value === AppointmentDTO.appointmentType.OTHER || value === AppointmentDTO.appointmentType.ABSENCE;
              const isVisiblePatientName = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisiblePhone = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;
              const isVisibleStatus = value === AppointmentDTO.appointmentType.ABSENCE ? false : true;

              const patientNameItemIndex = customItems.findIndex((item) => item.dataField === 'patientName');
              const endAtItemIndex = customItems.findIndex((item) => item.dataField === 'endAt');
              const phoneItemIndex = customItems.findIndex((item) => item.dataField === 'phoneNumber');
              const statusItemIndex = customItems.findIndex((item) => item.dataField === 'status');

              if (endAtItemIndex !== -1) {
                customItems[endAtItemIndex].visible = isVisibleEndAt;
              }
              if (patientNameItemIndex !== -1) {
                customItems[patientNameItemIndex].visible = isVisiblePatientName;
              }
              if (phoneItemIndex !== -1) {
                customItems[phoneItemIndex].visible = isVisiblePhone;
              }
              if (statusItemIndex !== -1) {
                customItems[statusItemIndex].visible = isVisibleStatus;
              }
              form.option('items', customItems);
            },
          },
        },
        {
          dataField: 'status',
          editorType: 'dxSelectBox',
          label: { text: `${t('appointmentStatus')}` },
          colSpan: 2,
          visible: e.appointmentData?.appointmentType !== AppointmentDTO.appointmentType.ABSENCE,
          editorOptions: {
            items: appointmentStatuses,
            valueExpr: 'value',
            displayExpr: 'displayValue',
            onInitialized: (e: { component: dxSelectBox }) => {
              const { component } = e;
              const value = component.option('value');
              if (value === null) {
                e.component.option('value', appointmentStatuses[0].value);
                form.updateData({ status: appointmentStatuses[0].value });
              }
            },
          },
        },
        {
          dataField: 'startAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.startAt')}` },
        },
        {
          dataField: 'endAt',
          colSpan: 2,
          editorType: 'dxDateBox',
          editorOptions: { type: 'datetime' },
          label: { text: `${t('appointmentForm.endAt')}` },
          visible:
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.ABSENCE ||
            e.appointmentData?.appointmentType === AppointmentDTO.appointmentType.OTHER,
        },
      ];

      form.option('items', customItems);
    },
    [appointmentStatusesTranslations, appointmentTypesTranslations, t],
  );

  const onCreate = useCallback(
    (e: { appointmentData: Appointment }) => {
      const { appointmentData } = e;

      let newAppointment: CreateAppointmentDTO = {
        userId: appointmentData.userId ?? 0,
        patientName: appointmentData.patientName,
        phoneNumber: appointmentData.phoneNumber,
        status: appointmentData.status,
        appointmentType: appointmentData.appointmentType,
        startAt: appointmentData.startAt,
      };
      if (appointmentData.status === 'OTHER' || 'ABSENCE') {
        newAppointment.endAt = appointmentData.endAt;
      }
      createAppointment({
        appointmentDto: newAppointment,
      });
    },
    [createAppointment],
  );

  const onEdit = useCallback(
    (e: { newData: Appointment }) => {
      const { newData } = e;
      updateAppointment({ appointmentId: newData.id, appointmentDto: newData as AppointmentDTO });
    },
    [updateAppointment],
  );

  const calculateStartHour = useCallback(() => {
    const schedulerWeekday = schedulerRef?.current?.instance
      .getEndViewDate()
      .toLocaleString('en', { weekday: 'long' })
      .toUpperCase();

    const startHours = workingHoursForCurrentView
      ?.filter((wh: any) => wh.dayOfWeek === schedulerWeekday)
      .map((wh: any) => wh.startTime);

    if (startHours && startHours.length > 0) {
      const earliestHour = Math.min(...startHours.map((time: any) => new Date(time).getHours()));
      return earliestHour;
    }

    return 6;
  }, [workingHoursForCurrentView]);

  const calculateEndHour = useCallback(() => {
    const schedulerWeekday = schedulerRef?.current?.instance
      .getEndViewDate()
      .toLocaleString('en', { weekday: 'long' })
      .toUpperCase();

    const endHours = workingHoursForCurrentView
      ?.filter((wh: any) => wh.dayOfWeek === schedulerWeekday)
      .map((wh: any) => wh.endTime);

    if (endHours && endHours.length > 0) {
      const latestHour = Math.max(...endHours.map((time: any) => new Date(time).getHours()));
      //add +1 hour in order not to cut last appointments
      return latestHour + 1;
    }
    return 20;
  }, [workingHoursForCurrentView]);
  return (
    <>
      <LoadPanel
        visible={isFetchingCalendar}
        position={{ my: 'center', at: 'center', of: `#schedulerBySpeciality` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      {allSpecialities && (
        <ScrollView height="100%" width="100%">
          <Scheduler
            id="schedulerBySpeciality"
            ref={schedulerRef}
            height={'auto'}
            dataSource={cloneDeep(calendarData?.appointments) ?? []}
            cellDuration={10}
            defaultCurrentView="timelineDay"
            groups={['userId']}
            maxAppointmentsPerCell={1}
            recurrenceRuleExpr="recurrence"
            startDayHour={calculateStartHour()}
            endDayHour={calculateEndHour()}
            startDateExpr="startAt"
            endDateExpr="endAt"
            appointmentRender={appointmentRenderer}
            onAppointmentRendered={onAppointmentRender}
            dataCellTemplate={dataCellTemplate}
            onContentReady={onContentReady}
            onAppointmentFormOpening={onAppointmentFormOpening}
            onAppointmentAdding={onCreate}
            onAppointmentUpdating={onEdit}
            appointmentDragging={{
              onDragStart(e) {
                e.cancel = true;
              },
            }}
            resourceCellRender={resourceCellRender}
          >
            <View type="timelineDay" groupOrientation="vertical" />
            <Resource dataSource={calendarData?.schedules ?? []} fieldExpr="userId" valueExpr="userId" />
            <Scrolling mode="virtual" />
          </Scheduler>
        </ScrollView>
      )}
    </>
  );
};
