import React from 'react';
import { CityGrid } from '../../../components/CityGrid/CityGrid';

export default function City() {
  return (
    <div className={'content-block'}>
      <CityGrid />
    </div>
  );
}
