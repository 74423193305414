/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Appointment } from './Appointment';
import type { Clinic } from './Clinic';
import type { LocalizedString } from './LocalizedString';
import type { Schedule } from './Schedule';
import type { Speciality } from './Speciality';

export type User = {
    id?: number;
    keycloakId?: string;
    firstName?: Array<LocalizedString>;
    lastName?: Array<LocalizedString>;
    username?: string;
    phoneNumber?: string;
    emailAddress?: string;
    address?: string;
    pictureFileName?: string;
    type?: User.type;
    lastLogin?: string;
    appointments?: Array<Appointment>;
    schedules?: Array<Schedule>;
    clinics?: Array<Clinic>;
    specialities?: Array<Speciality>;
    internal?: boolean;
};

export namespace User {

    export enum type {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        REGISTRATOR = 'REGISTRATOR',
        SPECIALIST = 'SPECIALIST',
        ASSISTANT = 'ASSISTANT',
    }


}

