import './themes/generated/theme.base.css';
import './dx-styles.scss';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useScreenSizeClass } from './utils/mediaQuery';
import Content from './content-entrypoints/Content';

/**
 * Provide the actual content for the application which is one of the following:
 *    - ErrorDisplay: In case there is an error
 *    - NotAuthenticatedContent: Content for users that are not authenticated yet (shows the login page)
 *    - Content: The actual content when the user is authenticated
 */
function provideContent(error: boolean) {
  // First, check if there is an error. If so, display the ErrorContent
  if (error) {
    return <span>error content</span>;
  }

  // If no errors => Show the main content layout.
  return <Content />;
}

/** This functions provides the entry point to the entire application */
function App() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <BrowserRouter>
      <div className={`app ${screenSizeClass}`}>{provideContent(false)}</div>
    </BrowserRouter>
  );
}

export default App;
