import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCity: build.mutation<UpdateCityApiResponse, UpdateCityApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/city/${queryArg.cityId}/edit`,
        method: 'PUT',
        body: queryArg.cityMultiLangDto,
      }),
    }),
    createCity: build.mutation<CreateCityApiResponse, CreateCityApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/city/create`,
        method: 'POST',
        body: queryArg.cityMultiLangDto,
        params: { language: queryArg.language },
      }),
    }),
    getCityDetails: build.query<GetCityDetailsApiResponse, GetCityDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/city/details/${queryArg.cityId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllCity: build.query<GetAllCityApiResponse, GetAllCityApiArg>({
      query: (queryArg) => ({ url: `/api/v1/city/all`, params: { language: queryArg.language } }),
    }),
    getAllCityMultiLang: build.query<GetAllCityMultiLangApiResponse, GetAllCityMultiLangApiArg>({
      query: () => ({ url: `/api/v1/city/all-multilingual` }),
    }),
    deleteCity: build.mutation<DeleteCityApiResponse, DeleteCityApiArg>({
      query: (queryArg) => ({ url: `/api/v1/city/${queryArg.cityId}/delete`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateCityApiResponse = /** status 200 OK */ object;
export type UpdateCityApiArg = {
  cityId: number;
  cityMultiLangDto: CityMultiLangDto;
};
export type CreateCityApiResponse = /** status 200 OK */ object;
export type CreateCityApiArg = {
  language: string;
  cityMultiLangDto: CityMultiLangDto;
};
export type GetCityDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetCityDetailsApiArg = {
  cityId: number;
  language?: string;
};
export type GetAllCityApiResponse = /** status 200 OK */ CityMultiLangDto[];
export type GetAllCityApiArg = {
  language: string;
};
export type GetAllCityMultiLangApiResponse = /** status 200 OK */ CityMultiLangDto[];
export type GetAllCityMultiLangApiArg = void;
export type DeleteCityApiResponse = /** status 200 OK */ object;
export type DeleteCityApiArg = {
  cityId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type CityMultiLangDto = {
  id?: number;
  name: LocalizedStringDto[];
  code?: string;
  countryId: number;
  regionId?: number;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateCityMutation,
  useCreateCityMutation,
  useGetCityDetailsQuery,
  useGetAllCityQuery,
  useGetAllCityMultiLangQuery,
  useDeleteCityMutation,
} = injectedRtkApi;
