import React from 'react';
import { CountryGrid } from '../../../components/CountryGrid/CountryGrid';

export default function Country() {
  return (
    <div className={'content-block'}>
      <CountryGrid />
    </div>
  );
}
