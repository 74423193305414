import { useEffect, useState } from 'react';
import { SchedulerComponent } from '../../components/SchedulerComponent/SchedulerComponent';
import { populateSearchWidgets, search } from '../../components/Algolia/instantsearch';
import { AlgoliaItem, startAutocomplete } from '../../components/Algolia/autocomplete';
import '@algolia/autocomplete-theme-classic';
import './schedule.scss';
import { useAppSelector } from '../../redux/store';
import { userClinicIds } from '../../redux/modules/user';

export default function Schedule() {
  const [user, setUser] = useState<AlgoliaItem | null>(null);

  const clinicIdList = useAppSelector(userClinicIds);

  document.addEventListener('algoliaSearchStateUpdated', (e: any) => {
    setUser(e.detail);
  });

  useEffect(() => {
    const autoComplete = document.getElementsByClassName('aa-Autocomplete');
    //check to inject or not the algolia components
    if (autoComplete.length === 0) {
      populateSearchWidgets();
      if (!search.started) search.start();
      startAutocomplete(search, clinicIdList ?? []);
    }
  }, [clinicIdList]);

  return (
    <div className={'content-block'}>
      <div id="autocomplete" />
      <SchedulerComponent user={user} />
    </div>
  );
}
