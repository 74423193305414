import { CheckBox, DataGrid, Popup, ScrollView } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import React, { useCallback, useState } from 'react';
import { Appointment, AppointmentDTO, User } from '../../api/medapp/generated';
import { calculateAppointmentColor } from '../helpers';
import { useTranslation } from 'react-i18next';
import { useGetCurrentUserQuery } from '../../api/medapp/user.generated';
import { useLanguage } from '../hooks/useLanguageHook';
import { ObjectRevisionDto, useGetAppointmentDetailsQuery } from '../../api/medapp/appointment.generated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'devextreme-react/data-grid';
import { DateTimeFormatOptions } from 'luxon';

export interface TooltipProps {
  onDeleteButtonClick: (e: any) => void;
  onUserAttended: (е: any, status: Appointment.status) => void;
  appointmentData: AppointmentDTO;
}

const dateTimeOptions: DateTimeFormatOptions = {
  //weekday: 'long',
  //year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const Tooltip = (props: TooltipProps) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const { currentData: currentUser } = useGetCurrentUserQuery();
  const { currentData: appointmentRevision } = useGetAppointmentDetailsQuery(
    {
      appointmentId: props.appointmentData.id ?? 0,
      language: language,
    },
    { refetchOnMountOrArgChange: true },
  );

  const calculateOptionsVisible = useCallback(() => {
    if (
      props.appointmentData.status === AppointmentDTO.status.SCHEDULED &&
      props.appointmentData.appointmentType !== AppointmentDTO.appointmentType.ABSENCE
    ) {
      if (currentUser?.type !== User.type.SPECIALIST) {
        return true;
      } else {
        if (props.appointmentData.userId === currentUser.id) {
          return true;
        } else return false;
      }
    }
  }, [
    currentUser?.id,
    currentUser?.type,
    props.appointmentData.appointmentType,
    props.appointmentData.status,
    props.appointmentData.userId,
  ]);

  const formatTime = useCallback((date: any) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }, []);

  const calculateRevisionDate = useCallback(
    (data: any) => {
      const startDateTime = new Date(data.revisionDate);
      const startDateString = startDateTime.toLocaleDateString(language, dateTimeOptions);
      const result = `${startDateString} `;
      return result;
    },
    [language],
  );

  const calculateRevisionTypeTranslation = useCallback(
    (data: ObjectRevisionDto) => {
      switch (data.revisionType) {
        case 'ADD':
          return t('toolTip.revisionPopup.ADD');
        case 'MOD':
          return t('toolTip.revisionPopup.MOD');
      }
    },
    [t],
  );

  const calculateAppointmentTypeTranslation = useCallback(
    (data: any) => {
      switch (data.object.appointmentType) {
        case Appointment.type.INITIAL:
          return t('appointmentTypes.INITIAL');
        case Appointment.type.SECONDARY:
          return t('appointmentTypes.SECONDARY');
        case Appointment.type.OPEN:
          return t('appointmentTypes.OPEN');
        case Appointment.type.OTHER:
          return t('appointmentTypes.OTHER');
        case Appointment.type.ABSENCE:
          return t('appointmentTypes.ABSENCE');
      }
    },
    [t],
  );
  const calculateUserName = useCallback(
    (data: ObjectRevisionDto) => {
      if (data.user === null) {
        return t('toolTip.revisionPopup.deletedUser');
      } else {
        return `${data.user?.firstName} ${data.user?.lastName}`;
      }
    },
    [t],
  );

  const calculateAppointmentDuration = useCallback((data: any) => {
    const startDateAndTime = new Date(data.object?.startAt);
    const endDateAndTime = new Date(data.object?.endAt);

    const startDateString = startDateAndTime.toLocaleDateString(language, dateTimeOptions);
    const endDateString = endDateAndTime.toLocaleDateString(language, dateTimeOptions);

    if (startDateAndTime.getDate() === endDateAndTime.getDate()) {
      const endTime = endDateAndTime.toLocaleTimeString(language,{hour: '2-digit', minute: '2-digit'});
      return `${startDateString} - ${endTime}`;
    } else {
      return `${startDateString} - ${endDateString}`;
    }
  }, [language]);

  return (
    <div>
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        showCloseButton={true}
        title={`${t('toolTip.revisionPopup.title')}`}
      >
        <ScrollView showScrollbar="always" direction="both" useNative={false}>
          <DataGrid dataSource={appointmentRevision} columnAutoWidth={true}>
            <Column
              dataField={'revisionType'}
              caption={`${t('toolTip.revisionPopup.revisionType')}`}
              calculateCellValue={calculateRevisionTypeTranslation}
            />
            <Column
              dataField={'revisionDate'}
              caption={`${t('toolTip.revisionPopup.date')}`}
              calculateCellValue={calculateRevisionDate}
              dataType={'date'}
            />
            <Column
              dataField={'object.appointmentType'}
              caption={`${t('toolTip.revisionPopup.appointmentType')}`}
              calculateCellValue={calculateAppointmentTypeTranslation}
            />
            {/* show patientName when hoover */}
            <Column dataField={'object.patientName'} caption={`${t('toolTip.revisionPopup.patientName')}`} />
            <Column dataField={'object.phoneNumber'} caption={`${t('toolTip.revisionPopup.phoneNumber')}`} />
            <Column
              caption={`${t('toolTip.revisionPopup.duration')}`}
              calculateCellValue={calculateAppointmentDuration}
              {...dateTimeOptions}
            />
            <Column
              dataField={'user'}
              caption={`${t('toolTip.revisionPopup.createdBy')}`}
              calculateCellValue={calculateUserName}
            />
          </DataGrid>
        </ScrollView>
      </Popup>
      <div className={'dx-tooltip-appointment-item'}>
        <div className={'dx-tooltip-appointment-item-marker'}>
          <div
            className={'dx-tooltip-appointment-item-marker-body'}
            style={{ backgroundColor: calculateAppointmentColor(props.appointmentData) }}
          />
        </div>
        <div className={'dx-tooltip-appointment-item-content'}>
          <div className={'dx-tooltip-appointment-item-content-subject'}>
            {props.appointmentData.patientName}
            <CheckBox
              visible={calculateOptionsVisible()}
              text={`${t('toolTip.attend')}`}
              onValueChanged={(e) => props.onUserAttended(e, Appointment.status.COMPLETED)}
              style={{ marginLeft: '15px' }}
            />
            <CheckBox
              visible={calculateOptionsVisible()}
              text={`${t('toolTip.cancel')}`}
              onValueChanged={(e) => props.onUserAttended(e, Appointment.status.CANCELLED)}
              style={{ marginLeft: '15px' }}
            />
          </div>
          <div className={'dx-tooltip-appointment-item-content-date'}>
            {new Date(props.appointmentData.startAt ?? '').toLocaleDateString()}{' '}
            {formatTime(new Date(props.appointmentData.startAt ?? ''))} -{' '}
            {formatTime(new Date(props.appointmentData.endAt ?? ''))}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            stylingMode="text"
            style={{ borderRadius: '50%' }}
            render={() => <FontAwesomeIcon icon="eye" />}
            onClick={(e) => {
              setIsPopupVisible(true);
              e.event?.stopPropagation();
            }}
          />
          <Button
            className={'dx-tooltip-appointment-item-delete-button'}
            icon="trash"
            stylingMode="text"
            onClick={props.onDeleteButtonClick}
            visible={currentUser?.type === User.type.ASSISTANT ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
