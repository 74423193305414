import { DataGrid, Column, Editing, LoadPanel as LoadPanelProp } from 'devextreme-react/data-grid';
import {
  useEditWorkingHoursMutation,
  useDeleteWorkingHoursMutation,
  useCreateWorkingHoursMutation,
  WorkingHoursDto,
  useGetWorkingHoursByScheduleQuery,
} from '../../api/medapp/workingHours.generated';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadPanel, SelectBox } from 'devextreme-react';
import { cloneDeep, differenceWith } from 'lodash';
import { WorkingHours } from '../../api/medapp/generated/models/WorkingHours';
import notify from 'devextreme/ui/notify';
import { ScheduleDTO } from '../../api/medapp/generated';

const dateBoxConfig = {
  dataType: 'datetime',
  format: 'shortTime',
  editorOptions: { calendarOptions: { visible: false } },
};

export const WorkingHoursDetailGrid = (props: { schedule: ScheduleDTO }) => {
  const { t } = useTranslation();
  const [updateWorkingHours] = useEditWorkingHoursMutation();
  const [createWorkingHours] = useCreateWorkingHoursMutation();
  const [deleteWorkingHours] = useDeleteWorkingHoursMutation();
  const { currentData: allWorkingHours, isFetching: isFetchingWorkingHours } = useGetWorkingHoursByScheduleQuery({
    scheduleId: props.schedule.id ?? 0,
  });

  const dayOfWeekEnum = Object.values(WorkingHours.dayOfWeek);

  const updateWorkingHoursAtribute = useCallback(
    (e: { oldData: WorkingHoursDto; newData: WorkingHoursDto }) => {
      const { oldData, newData } = e;
      const updatedWorkingHoursDto = { ...oldData, ...newData };
      if (oldData.id) {
        updateWorkingHours({
          workingHoursId: oldData.id,
          workingHoursDto: updatedWorkingHoursDto,
        });
      } else notify('Cannot update WorkingHour, has not id!', 'warning', 5000);
    },
    [updateWorkingHours],
  );

  const onCreate = useCallback(
    (e: { data: any }) => {
      if (props.schedule.id) {
        const startTime = new Date(props.schedule.startTime);
        const endTime = new Date(props.schedule.endTime);
        startTime.setHours(new Date(e.data.startTime).getHours(), new Date(e.data.startTime).getMinutes());
        endTime.setHours(new Date(e.data.endTime).getHours(), new Date(e.data.endTime).getMinutes());

        createWorkingHours({
          scheduleId: props.schedule.id,
          workingHoursDto: {
            ...e.data,
            startTime: startTime,
            endTime: endTime,
          },
        });
      } else notify('Cannot create, no scheduleId!', 'warning', 5000);
    },
    [createWorkingHours, props.schedule.endTime, props.schedule.id, props.schedule.startTime],
  );

  const onDelete = useCallback(
    (e: { data: any }) => {
      const { data } = e;

      deleteWorkingHours({
        workingHoursId: data.id,
      });
    },
    [deleteWorkingHours],
  );

  const calculateDayTranslation = useCallback(
    (data: any) => {
      switch (data) {
        case 'MONDAY':
          return t('dayOfWeeks.MONDAY');
        case 'TUESDAY':
          return t('dayOfWeeks.TUESDAY');
        case 'WEDNESDAY':
          return t('dayOfWeeks.WEDNESDAY');
        case 'THURSDAY':
          return t('dayOfWeeks.THURSDAY');
        case 'FRIDAY':
          return t('dayOfWeeks.FRIDAY');
        case 'SATURDAY':
          return t('dayOfWeeks.SATURDAY');
        case 'SUNDAY':
          return t('dayOfWeeks.SUNDAY');
      }
    },
    [t],
  );

  const calculateWorkDays = useCallback(() => {
    const workingDays = allWorkingHours?.map((wh: any) => wh.dayOfWeek);
    const days = differenceWith(dayOfWeekEnum, workingDays ?? [], (a, b) => a === b);
    return generateItems(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWorkingHours]);

  //generate daysOfWeek for dropdown
  const generateItems = useCallback(
    (data: WorkingHours.dayOfWeek[]) => {
      const items = data.map((day: WorkingHours.dayOfWeek) => ({
        value: day,
        displayValue: calculateDayTranslation(day),
      }));
      return items;
    },
    [calculateDayTranslation],
  );

  const dayOfWeekRenderer = useCallback(
    (e: any) => {
      return (
        <SelectBox
          dataSource={calculateWorkDays()}
          valueExpr={'value'}
          defaultValue={e.value}
          displayExpr={'displayValue'}
          onValueChanged={(l) => {
            e.setValue(l.value);
          }}
        />
      );
    },
    [calculateWorkDays],
  );

  return (
    <>
      <LoadPanel
        visible={isFetchingWorkingHours}
        position={{ my: 'center', at: 'center', of: `#WorkingHoursDetailGrid` }}
      />
      <DataGrid
        id="WorkingHoursDetailGrid"
        height={'100%'}
        width={'100%'}
        dataSource={cloneDeep(allWorkingHours)}
        showBorders={true}
        onRowUpdating={updateWorkingHoursAtribute}
        onRowRemoved={onDelete}
        onRowInserted={onCreate}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} />
        <Column
          dataField={'dayOfWeek'}
          caption={`${t('workingHoursDetailGrid.workDay')}`}
          calculateCellValue={(rowData: WorkingHoursDto) => calculateDayTranslation(rowData.dayOfWeek)}
          editCellRender={dayOfWeekRenderer}
        />
        <Column dataField={'startTime'} caption={`${t('workingHoursDetailGrid.startTime')}`} {...dateBoxConfig} />
        <Column dataField={'endTime'} caption={`${t('workingHoursDetailGrid.endTime')}`} {...dateBoxConfig} />
        <Column
          dataField={'breakStartTime'}
          caption={`${t('workingHoursDetailGrid.breakStartTime')}`}
          {...dateBoxConfig}
        />
        <Column dataField={'breakEndTime'} caption={`${t('workingHoursDetailGrid.breakEndTime')}`} {...dateBoxConfig} />
      </DataGrid>
    </>
  );
};
