import { useGetClinicsForCurrentUserQuery } from '../../api/medapp/clinic.generated';
import { useRegisterApplicationUserMutation, LocalizedStringDto } from '../../api/medapp/user.generated';
import { UserMultilang } from '../../api';
import { LoadPanel, Popup, ScrollView } from 'devextreme-react';
import {
  Form,
  SimpleItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
  Label,
  CompareRule,
  EmailRule,
  RequiredRule,
} from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserNameDetailGrid, UserNameDto } from './UserNameDetailGrid';
import { useAppSelector } from '../../redux/store';
import { createUserDialogVisibleSelector, toggleCreateUserDialog } from '../../redux/modules/user';
import { useDispatch } from 'react-redux';
import { useGetAllSpecialityQuery } from '../../api/medapp/speciality.generated';
import { Position } from 'devextreme-react/load-panel';
import { getTranslatedValue, generateItems } from '../helpers';
import { cloneDeep } from 'lodash';

const emptyUser: UserMultilang = {
  username: '',
  firstName: [
    { language: 'bg', value: '' },
    { language: 'en', value: '' },
  ],
  lastName: [
    { language: 'bg', value: '' },
    { language: 'en', value: '' },
  ],
};

export const CreateUserDialog = () => {
  const formRef = useRef<Form>(null);

  const { t, i18n } = useTranslation();

  const userTypesTranslations = t('userTypes', { returnObjects: true });

  const [newUserData, setNewUserData] = useState<UserMultilang>(cloneDeep(emptyUser));

  const language = useMemo(() => i18n.language, [i18n.language]);
  const dispatch = useDispatch();
  const toggleDialog = useCallback(() => dispatch(toggleCreateUserDialog()), [dispatch]);
  const showAddUserDialog = useAppSelector(createUserDialogVisibleSelector);

  useEffect(() => {
    return () => {
      setNewUserData(cloneDeep(emptyUser));
    };
  }, [showAddUserDialog]);

  const { currentData: allSpecialities, isFetching: isFetchingSpecialities } = useGetAllSpecialityQuery({
    language: language,
  });
  const { currentData: allClinicsForCurrentUser, isFetching: isFetchingClinicsForCurrentUser } =
    useGetClinicsForCurrentUserQuery({ lang: language });
  const [createUser] = useRegisterApplicationUserMutation();

  const calculateSpeciality = useCallback(
    (data: { name: LocalizedStringDto[] }) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  const finish = useCallback(() => {
    createUser({ userMultilangDto: newUserData });
    toggleDialog();
  }, [createUser, newUserData, toggleDialog]);

  const UserNameDetailGridRenderer = () => {
    const languages = emptyUser.firstName?.map((name: LocalizedStringDto) => {
      return name.language;
    });
    const dataSource: UserNameDto[] = [];
    languages?.forEach((language: string) => {
      dataSource.push({
        language: language,
        firstName: getTranslatedValue(newUserData.firstName ?? [], language) ?? '',
        lastName: getTranslatedValue(newUserData.lastName ?? [], language) ?? '',
      });
    });

    return (
      <UserNameDetailGrid
        dataSource={dataSource}
        onRowUpdated={(data: UserNameDto) => {
          const dto = { ...newUserData };
          const fn = dto.firstName?.find((fn) => fn.language === data.language);
          const ln = dto.lastName?.find((ln) => ln.language === data.language);
          if (fn) fn.value = data.firstName;
          if (ln) ln.value = data.lastName;
          setNewUserData(dto);
        }}
      />
    );
  };

  if (!showAddUserDialog) {
    return <div></div>;
  }

  return (
    <Popup visible={true} resizeEnabled={true} showCloseButton={false}>
      <ScrollView>
        <LoadPanel
          visible={isFetchingClinicsForCurrentUser || isFetchingSpecialities}
          shading={true}
          shadingColor="rgba(0,0,0,.32)"
        >
          <Position my="center" at="center" of={`#CreateUserForm`} />
        </LoadPanel>
        <Form id="CreateUserForm" ref={formRef} formData={newUserData}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={`${t('createUserDialog.info')}`} colCount={2}>
              <SimpleItem dataField={'username'} isRequired>
                <Label text={`${t('createUserDialog.username')}`} />
              </SimpleItem>
              <SimpleItem dataField={'emailAddress'}>
                <Label text={`${t('createUserDialog.email')}`} />
                <EmailRule />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField={'password'} editorOptions={{ mode: 'password' }} isRequired>
                <Label text={`${t('createUserDialog.password')}`} />
              </SimpleItem>
              <SimpleItem name="ConfirmPassword" editorType="dxTextBox" editorOptions={{ mode: 'password' }} isRequired>
                <Label text={`${t('createUserDialog.confirmPassword')}`} />
                <CompareRule message="The passwords do not match." comparisonTarget={() => newUserData.password} />
              </SimpleItem>
              <SimpleItem dataField={'phoneNumber'}>
                <Label text={`${t('createUserDialog.phone')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'type'}
                editorType="dxSelectBox"
                isRequired
                editorOptions={{
                  items: generateItems(userTypesTranslations),
                  displayExpr: 'displayValue',
                  valueExpr: 'value',
                  searchEnabled: true,
                }}
              >
                <Label text={`${t('createUserDialog.type')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'specialityIds'}
                editorType="dxTagBox"
                isRequired
                editorOptions={{
                  items: allSpecialities,
                  displayExpr: calculateSpeciality,
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              >
                <Label text={`${t('createUserDialog.specialities')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'clinicIds'}
                editorType="dxTagBox"
                isRequired
                editorOptions={{
                  isRequired: true,
                  items: allClinicsForCurrentUser,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              >
                <Label text={`${t('createUserDialog.clinic')}`} />
              </SimpleItem>
            </Tab>
            <Tab title={`${t('createUserDialog.translations-names')}`}>
              <SimpleItem render={UserNameDetailGridRenderer} />
            </Tab>
          </TabbedItem>
        </Form>
      </ScrollView>
      <ToolbarItem
        widget={'dxButton'}
        toolbar={'bottom'}
        location={'after'}
        options={{
          text: `${t('buttons.confirm')}`,
          elementAttr: { 'aria-label': 'confirm' },
          onClick: () => {
            const validationResult = formRef.current?.instance.validate();
            if (validationResult?.isValid) {
              finish();
            }
          },
        }}
      />
      <ToolbarItem
        widget={'dxButton'}
        toolbar={'bottom'}
        location={'after'}
        options={{
          text: `${t('buttons.cancel')}`,
          elementAttr: { 'aria-label': 'cancel' },
          onClick: () => toggleDialog(),
        }}
      />
    </Popup>
  );
};
