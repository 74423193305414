import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    unbindAppointments: build.mutation<UnbindAppointmentsApiResponse, UnbindAppointmentsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/unbind`,
        method: 'PUT',
        params: { appointmentIds: queryArg.appointmentIds },
      }),
    }),
    unbindAppointment: build.mutation<UnbindAppointmentApiResponse, UnbindAppointmentApiArg>({
      query: (queryArg) => ({ url: `/api/v1/appointment/unbind/${queryArg.appointmentId}`, method: 'PUT' }),
    }),
    editAppointment: build.mutation<EditAppointmentApiResponse, EditAppointmentApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/edit/${queryArg.appointmentId}`,
        method: 'PUT',
        body: queryArg.appointmentDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    updateAppointmentStatus: build.mutation<UpdateAppointmentStatusApiResponse, UpdateAppointmentStatusApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/edit/status/${queryArg.appointmentId}`,
        method: 'PUT',
        params: { status: queryArg.status },
      }),
    }),
    createAppointment: build.mutation<CreateAppointmentApiResponse, CreateAppointmentApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/create`,
        method: 'POST',
        body: queryArg.appointmentDto,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getHangingAppointmentsByUserId: build.query<
      GetHangingAppointmentsByUserIdApiResponse,
      GetHangingAppointmentsByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/appointment/hanging/${queryArg.userId}` }),
    }),
    getHangingAppointmentsForClinic: build.query<
      GetHangingAppointmentsForClinicApiResponse,
      GetHangingAppointmentsForClinicApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/appointment/hanging/clinic/${queryArg.clinicId}` }),
    }),
    getAppointmentDetails: build.query<GetAppointmentDetailsApiResponse, GetAppointmentDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/details/${queryArg.appointmentId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllAppointments: build.query<GetAllAppointmentsApiResponse, GetAllAppointmentsApiArg>({
      query: () => ({ url: `/api/v1/appointment/all` }),
    }),
    deleteAppointments: build.mutation<DeleteAppointmentsApiResponse, DeleteAppointmentsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/appointment/delete`,
        method: 'DELETE',
        params: { appointmentIds: queryArg.appointmentIds },
      }),
    }),
    deleteAppointment: build.mutation<DeleteAppointmentApiResponse, DeleteAppointmentApiArg>({
      query: (queryArg) => ({ url: `/api/v1/appointment/delete/${queryArg.appointmentId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UnbindAppointmentsApiResponse = /** status 200 OK */ object;
export type UnbindAppointmentsApiArg = {
  appointmentIds: number[];
};
export type UnbindAppointmentApiResponse = /** status 200 OK */ object;
export type UnbindAppointmentApiArg = {
  appointmentId: number;
};
export type EditAppointmentApiResponse = /** status 200 OK */ AppointmentDto[];
export type EditAppointmentApiArg = {
  appointmentId: number;
  timeZone?: string;
  appointmentDto: AppointmentDto;
};
export type UpdateAppointmentStatusApiResponse = /** status 200 OK */ object;
export type UpdateAppointmentStatusApiArg = {
  appointmentId: number;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
};
export type CreateAppointmentApiResponse = /** status 200 OK */ AppointmentDto[];
export type CreateAppointmentApiArg = {
  timeZone?: string;
  appointmentDto: AppointmentDto;
};
export type GetHangingAppointmentsByUserIdApiResponse = /** status 200 OK */ AppointmentDto[];
export type GetHangingAppointmentsByUserIdApiArg = {
  userId: number;
};
export type GetHangingAppointmentsForClinicApiResponse = /** status 200 OK */ AppointmentDto[];
export type GetHangingAppointmentsForClinicApiArg = {
  clinicId: number;
};
export type GetAppointmentDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetAppointmentDetailsApiArg = {
  appointmentId: number;
  language?: string;
};
export type GetAllAppointmentsApiResponse = /** status 200 OK */ AppointmentDto[];
export type GetAllAppointmentsApiArg = void;
export type DeleteAppointmentsApiResponse = /** status 200 OK */ object;
export type DeleteAppointmentsApiArg = {
  appointmentIds: number[];
};
export type DeleteAppointmentApiResponse = /** status 200 OK */ object;
export type DeleteAppointmentApiArg = {
  appointmentId: number;
};
export type AppointmentDto = {
  id?: number;
  userId: number;
  patientId?: number;
  patientName?: string;
  phoneNumber?: string;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
  appointmentType: 'INITIAL' | 'SECONDARY' | 'OPEN' | 'INSURANCE' | 'OTHER' | 'ABSENCE';
  startAt: string;
  endAt?: string;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUnbindAppointmentsMutation,
  useUnbindAppointmentMutation,
  useEditAppointmentMutation,
  useUpdateAppointmentStatusMutation,
  useCreateAppointmentMutation,
  useGetHangingAppointmentsByUserIdQuery,
  useGetHangingAppointmentsForClinicQuery,
  useGetAppointmentDetailsQuery,
  useGetAllAppointmentsQuery,
  useDeleteAppointmentsMutation,
  useDeleteAppointmentMutation,
} = injectedRtkApi;
