import React from 'react';
import { ClinicGrid } from '../../../components/ClinicGrid/ClinicGrid';

export default function Clinic() {
  return (
    <div className={'content-block'}>
      <ClinicGrid />
    </div>
  );
}
